<template>
    <v-card dark class="px-8 py-10" outlined tile>
        <h2 class="text-h2">{{ $t('start.heading') }}</h2>
        <div class="overline">{{ $t('start.subheading') }}</div>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field ref="firstInput" v-model="model.contact_organisation" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" :label="$t('start.label_contact_organisation')" :placeholder="$t('start.placeholder_contact_organisation')" autocomplete="chrome-off"/>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="model.contact_chamber_of_commerce" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" :label="$t('start.label_contact_chamber_of_commerce')" :placeholder="$t('start.placeholder_contact_chamber_of_commerce')" autocomplete="chrome-off"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field v-model="model.contact_name" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" :label="$t('start.label_contact_name')" :placeholder="$t('start.placeholder_contact_name')" autocomplete="chrome-off"/>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="model.contact_address" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" :label="$t('start.label_contact_address')" :placeholder="$t('start.placeholder_contact_address')" autocomplete="chrome-off"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field v-model="model.contact_postal" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" :label="$t('start.label_contact_postal')" :placeholder="$t('start.placeholder_contact_postal')" autocomplete="chrome-off"/>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="model.contact_city" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" :label="$t('start.label_contact_city')" :placeholder="$t('start.placeholder_contact_city')" autocomplete="chrome-off"/>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="overline" v-if="project">{{ $t('start.heading_project_information', [project.name]) }}</div>
        <v-textarea v-model="model.project_scope" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" rows="3" :label="$t('start.label_project_scope')" autocomplete="chrome-off"/>
        <v-textarea v-model="model.project_motivation" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" rows="3" :label="$t('start.label_project_motivation')" autocomplete="chrome-off"/>
        <v-textarea v-model="model.project_budget" @change="onChange" :filled="!formDisabled" :disabled="formDisabled" rows="3" :label="$t('start.label_project_budget')" autocomplete="chrome-off"/>
        <v-card dark class="pl-4" outlined tile>
            <v-switch v-model="model.option_additional" @change="onChange" :disabled="formDisabled" :label="$t('start.label_option_additional')"/>
            <v-textarea v-model="model.option_additional_info" @change="onChange" v-if="model.option_additional" :disabled="formDisabled" :label="$t('start.label_short_description')" rows="3" autocomplete="chrome-off"/>
        </v-card>
        <v-card dark class="pl-4 mt-1" outlined tile>
            <v-switch v-model="model.option_dependency" @change="onChange" :disabled="formDisabled" :label="$t('start.label_option_dependency')"/>
            <v-textarea v-model="model.option_dependency_info" @change="onChange" v-if="model.option_dependency" :disabled="formDisabled" :label="$t('start.label_short_description')" rows="3" autocomplete="chrome-off"/>
        </v-card>
        <v-btn v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_START)" :disabled="!$isStepReleased(PROJECT_START)" class="mt-3 boxed-icon" color="primary" tile @click="$approveStep(PROJECT_START)">
            {{ $t('workflow.save_continue') }}
            <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn v-else-if="$inGroupSuper() && !$isStepReleased(PROJECT_START)" class="mt-3 boxed-icon" color="primary" tile @click="$releaseStep(PROJECT_START)">
            {{ $t('workflow.release_to_architect') }}
            <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <template v-else>
            <v-btn class="mt-3 mr-3 boxed-icon" tile color="primary" :disabled="$isStepApproved(PROJECT_START)">
                {{ $t('global.save') }}
                <v-icon right>mdi-check</v-icon>
            </v-btn>
        </template>
    </v-card>
</template>

<script>
import { debounce } from 'debounce';
import { mapState } from 'vuex';
import { createForm, updateFirestoreForm } from '@/firebase/firestore/projects/forms';
import { FORM_START } from '@/utils';

export default {
    name: 'TheProjectStart',

    data: () => ({
        extraInfoAvalible: true,
        extraDependency: false,
        formStart: {},
        formChanged: false,
        template: {
            contact_address: '',
            contact_postal: '',
            contact_name: '',
            contact_chamber_of_commerce: '',
            contact_organisation: '',
            contact_city: '',
            project_scope: '',
            project_motivation: '',
            project_budget: '',
            option_additional: false,
            option_additional_info: '',
            option_dependency: false,
            option_dependency_info: '',
        },
        model: {},
    }),

    computed: {
        ...mapState('project', {
            project: 'boundInstance',
        }),
        formDisabled: function () {
            return !this.$store.getters['workflow/editableOfStart'];
        },
    },

    watch: {
        formStart () {
            this.updateModel();
        },

        project: {
            handler () {
                this.updateFirestoreBindings();
            }
        },

        '$store.state.authentication.user': {
            immediate: true,
            handler () {
                this.updateFirestoreBindings();
            }
        },
    },

    async mounted () {
        if (!this.formDisabled) {
            this.$refs.firstInput.focus();
        }
    },

    methods: {
        updateModel () {
            this.model = {...this.template, ...this.formStart?.data};
        },

        async updateFirestoreBindings () {
            if (!this.$store.getters['authentication/getUser'].uid || !this.project?.id) {
                if (typeof this._firestoreUnbinds['formStart'] !== 'undefined') {
                    this.$unbind('formStart', true);
                }
                return;
            }

            const refForms = this.project.$ref.collection('forms');
            const docForm = refForms.doc(FORM_START);

            if (!this.$store.getters['workflow/editableOfStart']) {
                // Eenwegsbinding (geen synchronisatie vanuit Firestore nodig)
                const snapForm = await docForm.get();
                this.formStart = snapForm.data();
                console.debug('Form-data eenmalig ingeladen zonder binding met updates');
            } else {
                // Bind via Vuefire
                return this.$bind('formStart', docForm)
                    .finally(() => console.debug('Binding met form-data opgezet'));
            }
        },

        onChange: debounce(function () {
            updateFirestoreForm({
                documentPath: 'projects/' + this.project.id + '/forms/start',
                data: createForm({...this.model}, this.formStart.users),
            });
        }, 500),
    },
};
</script>

<style scoped lang="scss">
::v-deep .theme--dark.v-text-field.v-input--is-disabled .v-input__slot {
    &::before {
        border: 0;
    }

    input, textarea, select {
        pointer-events: none;
    }
}
</style>
